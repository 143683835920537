class AppState {
  isPl() {
    return window.location.hostname === 'engenious.pl';
  }

  isCh() {
    return window.location.hostname === 'engenious.ch';
  }
}

export const appState = new AppState();
