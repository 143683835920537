
import { Options, Vue } from "vue-class-component";
import IconArrowLink from "@/icons/icon-arrow-link.vue";
import IconSubmenuShow from "@/icons/icon-submenu-show.vue";
import IconSubmenuHide from "@/icons/icon-submenu-hide.vue";
import IconLogo from "@/icons/icon-logo.vue";
import { appState } from "@/services/app.state";

@Options({
  components: { IconLogo, IconSubmenuHide, IconSubmenuShow, IconArrowLink },
  props: {
    msg: String,
  },
})
export default class AppHeader extends Vue {
  mobileMenuVisible = false;
  scrollPosition = 0;
  scrollUp = false;
  scrollDown = false;
  sticky = false;
  submenuVisible: number | null = null;
  submenuMobileVisible: number | null = null;

  menuItems: any = [
    { text: "Home", to: "/" },
    { text: "Services", to: "/our-services" },
    {
      text: "Success Stories",
      to: "/success-stories",
      header: "Explore&nbsp;all<br />success<br />stories",
      submenu: [
        {
          text: "All success stories",
          to: "/success-stories",
          isArchive: true,
        },
        {
          text: "Cloud Solutions",
          to: "/success-stories/cloud-solutions",
        },
        {
          text: "Artificial Intelligence",
          to: "/success-stories/artificial-intelligence",
        },
        {
          text: "Cybersecurity",
          to: "/success-stories/cybersecurity",
        },
        { text: "Architecture", to: "/success-stories/architecture" },
        {
          text: "Data & Analytics",
          to: "/success-stories/data-analytics",
        },
        { text: "QA & Testing", to: "/success-stories/testing" },
        {
          text: "Software Development",
          to: "/success-stories/software-development",
        },
        { text: "DevOps", to: "/success-stories/devops" },
        { text: "Documentation", to: "/success-stories/documentation" },
      ],
    },
    ...(appState.isCh()
      ? []
      : [
          {
            text: "Our Team",
            to: "/our-team",
          },
        ]),
    { text: "Join Us", to: "/join-us" },
    { text: "Contact us", to: "/contact-us", isButton: true },
  ];

  toggleMenu(): void {
    if (window.innerWidth >= 1024) {
      return;
    }
    this.mobileMenuVisible = !this.mobileMenuVisible;
  }

  toggleSubmenuMobile(index: number): void {
    if (window.innerWidth >= 1024) {
      return;
    }
    this.submenuMobileVisible =
      this.submenuMobileVisible === index ? null : index;
  }

  hideSubmenuAfterClick(): void {
    this.hideSubmenuDesktop();
    this.hideSubmenuMobile();
  }

  showSubmenuDesktop(index: number): void {
    if (window.innerWidth < 1024) {
      return;
    }
    this.submenuVisible = index;
  }

  hideSubmenuDesktop(): void {
    if (window.innerWidth < 1024) {
      return;
    }
    this.submenuVisible = null;
  }

  hideSubmenuMobile(): void {
    if (window.innerWidth >= 1024) {
      return;
    }
    this.submenuMobileVisible = null;
  }
}
