import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_hero = _resolveComponent("hero")!
  const _component_home_the_best = _resolveComponent("home-the-best")!
  const _component_your_needs = _resolveComponent("your-needs")!
  const _component_industries = _resolveComponent("industries")!
  const _component_home_our_stories = _resolveComponent("home-our-stories")!
  const _component_what_we_value = _resolveComponent("what-we-value")!
  const _component_home_our_people = _resolveComponent("home-our-people")!
  const _component_launch_your_product = _resolveComponent("launch-your-product")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_hero),
    _createVNode(_component_home_the_best),
    _createVNode(_component_your_needs),
    _createVNode(_component_industries),
    _createVNode(_component_home_our_stories, {
      "show-more-btn": true,
      "items-to-show": 6
    }),
    _createVNode(_component_what_we_value),
    (!_ctx.isCh)
      ? (_openBlock(), _createBlock(_component_home_our_people, { key: 0 }))
      : _createCommentVNode("", true),
    _createVNode(_component_launch_your_product)
  ]))
}